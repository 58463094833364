<template>
	<div class="order_wrap fixed_wrap customer_area">
		<!-- 상단 스태퍼 -->
		<MStepper :step="1" />
		<!--// 상단 스태퍼 -->

		<!-- 상단 타이틀 -->
		<div class="txt_box">
			<h1 class="tit_page">
				결제방법 선택
			</h1>
			<div class="txt_body1">
				주문 접수에 사용할 결제수단을 선택해 주세요. <br />결제수단을 등록하면,
				주문이 더욱 빨라집니다.
			</div>
		</div>
		<!--// 상단 타이틀 -->

		<!-- 대표결제수단 -->
		<v-form class="form">
			<v-expansion-panels focusable class="bor_0" v-model="panel">
				<v-expansion-panel class="accodion_select">
					<v-expansion-panel-header>
						결제 수단
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="payMethodSelectIdx"
						>
							<v-btn :style="'display:none;'"></v-btn>
							<v-btn
								outlined
								class="btn-remove"
								v-for="(item, i) in payMethod"
								:key="i"
							>
								<em class="val" @click="select(item)"
									>{{
										item.AF_PAY_MTHD_NM.length > 10
											? item.AF_PAY_MTHD_NM.substring(0, 10)
											: item.AF_PAY_MTHD_NM
									}}
									:&nbsp;{{ item.AF_BANK_CD_NM }}&nbsp;{{
										item.AF_PAY_MTHD === '11'
											? $options.filters.star(item.AF_CARD_NO, 4)
											: $options.filters.star(item.AF_CARD_NO, 6)
									}}</em
								>
								<div v-if="!item.SAP_YN" @click="listDel(i, item)">
									<v-icon>mdi-close</v-icon>
								</div>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>

				<p
					class="mb-8 no-contents"
					v-if="panel === undefined && selectPayment.length === 0"
				>
					선택된 결제수단이 없습니다.
				</p>

				<p
					class="mb-8 no-contents"
					v-else-if="panel === 0 && payMethod.length === 0"
				>
					등록된 결제 수단이 없습니다.
					<br />대표 결제수단을 추가해 주세요.
				</p>

				<!-- 선택된 결제 수단 -->
				<v-btn-toggle class="toggle_row" v-model="selectPaymentIdx" mandatory>
					<v-btn
						outlined
						class="btn-remove"
						v-if="panel === undefined && selectPayment.AF_CARD_NO"
					>
						<em class="val"
							>{{
								selectPayment.AF_PAY_MTHD_NM.length > 10
									? selectPayment.AF_PAY_MTHD_NM.substring(0, 10)
									: selectPayment.AF_PAY_MTHD_NM
							}}
							:&nbsp;{{ selectPayment.AF_BANK_CD_NM }}&nbsp;{{
								selectPayment.AF_PAY_MTHD === '11'
									? $options.filters.star(selectPayment.AF_CARD_NO, 4)
									: $options.filters.star(selectPayment.AF_CARD_NO, 6)
							}}</em
						>
					</v-btn>
				</v-btn-toggle>
				<!--// 선택된 결제 수단 -->

				<ul
					class="notice"
					v-if="panel === undefined && selectPayment.length !== 0"
				>
					<li>
						렌탈료와 관리서비스등 매 월 발생하는 비용은 선택된 결제수단으로
						청구됩니다.
					</li>
					<li>
						결제수단을 여러 개로 사용하고 싶은 경우, 대표 결제수단을 선택하신 뒤
						담당 코디분께 별도 변경 문의를 통해 가능합니다.
					</li>

					<li>
						수집된 결제수단정보는 계약체결이 되지 않은 경우 자동 파기합니다.
					</li>
				</ul>

				<v-btn
					class="btn_secondary mb-2"
					default
					large
					@click="payMethodPop()"
					v-if="selectPayment.length === 0 || panel === 0"
					>추가</v-btn
				>
			</v-expansion-panels>
		</v-form>
		<!--// 대표결제수단 -->

		<!-- 하단 버튼 -->
		<div class="bottom_box">
			<v-btn
				color="primary"
				large
				class="btn_primary"
				@click="next"
				:disabled="showInitSelected === false"
			>
				다음
			</v-btn>
		</div>
		<!--// 하단 버튼 -->

		<!-- 결제수단 추가 모달 -->
		<PayMethodPage
			v-if="paymentLimit !== null"
			@accountInfo="accountInfo"
			:saveable="false"
			:payDd="payDd"
			:once="false"
			:paymentLimit="paymentLimit"
			:paymentType="'cust'"
			ref="payment-add-popup"
		/>
		<!--// 결제수단 추가 모달 -->
		<CustInfoUpdate ref="phone-update" />
	</div>
</template>

<script>
import {
	fetchCustCheckSelf,
	fetchPernrLimitCheck,
	postCustCreateAndUpdate,
	fetchDecrypedPassVerifiedCust
} from '@/apis/order.verify.api'
import {
	paymentList,
	postWebOrderInfo,
	codyMatchingValidate,
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import { paymentInfo, getPayDate } from '@/apis/order.recp.api'
import { fetchCustPass72Check } from '@/apis/order.verify.api'
import filters from '@/mixins/filters'
import { mapActions } from 'vuex'
import CustInfoUpdate from './CustInfoUpdate'
import { isParameterEmpty } from '@/utils/common'

export default {
	name: 'PayAdd',
	mixins: [filters],
	components: { CustInfoUpdate },
	data() {
		return {
			webOrderNo: sessionStorage.getItem('orderId'),
			panel: 0, // 선택된 대표결제 수단 펼침
			selectPayment: [], // 선택된 결제수단
			selectPaymentIdx: 0,
			payMethod: [],
			payMethodSelectIdx: 0,
			query: {},
			storeInfo: [],
			pageView: true,
			paymentLimit: null, // 결제수단 제한 (C: 카드허용, B: 은행이체 허용)
			payDd: []
		}
	},

	computed: {
		showInitSelected() {
			return this.selectPayment.length !== 0
		}
	},

	async mounted() {
		if (this.isParameterEmpty(this.webOrderNo)) {
			const param = {
				title: '비정상적인 접근입니다.',
				msg: '다시 접속하여 진행 부탁 드립니다.'
			}
			this.$store.dispatch('common/setGuideData', param)
			this.$router.push({ name: 'guide' })
			return
		}

		const params = {
			type: '1', // 판매인만 보는 호출
			codyNo: this.$store.getters['common/codyNo']
		}
		this.$store.dispatch('verify/setMobOrderNo', this.webOrderNo)

		const res = await fetchWebOrderInfoById(this.webOrderNo)

		//삭제된 주문 체크
		if (res.resultObject.statusCode === '203') {
			this.$router.push({ name: 'verify-cust-deleted' })
			return
		}

		// 판매인별 결제 제한 체크
		const pernrLimitCheck = await fetchPernrLimitCheck(params)
		if (pernrLimitCheck.resultObject.data.E_RETURN.TYPE === 'F') {
			this.paymentLimit = 'C' // 카드만 허용
			this.$store.dispatch('common/setPaymentLimit', this.paymentLimit) // 스토어저장
			this.$router.push({
				name: 'cust-paycomplete',
				params: { paymentLimit: this.paymentLimit }
			})
		} else {
			this.paymentLimit = '' // 전체 허용
		}

		// 주문번호

		this.query = this.$route.query
		if (this.query.retInfo) {
			// 패스인증결과값으로 SAP에서 코디정보 가져오기
			this.$store.dispatch('custom/setRetInfo', this.query)
			if (
				sessionStorage.getItem('orderId') === undefined ||
				sessionStorage.getItem('orderId') === null ||
				sessionStorage.getItem('orderId') === ''
			) {
				const param = {
					title: '비정상적인 접근입니다.',
					msg: '다시 접속하여 진행 부탁 드립니다.'
				}
				this.$store.dispatch('common/setGuideData', param)
				this.$router.push({ name: 'guide' })
			} else {
				await this.passResultProcess(this.query.retInfo)
			}
		} else {
			let customer = JSON.parse(sessionStorage.getItem('customer'))
			if (customer === null) {
				const param = {
					title: '비정상적인 접근입니다.',
					msg: '다시 접속하여 진행 부탁 드립니다.'
				}
				this.$store.dispatch('common/setGuideData', param)
				this.$router.push({ name: 'guide' })
			}
			this.$store.dispatch('verify/setCustomer', customer)
			const custParams = {
				name: customer.name,
				phoneNo: customer.phone,
				id: this.webOrderNo
			}
			const pass72Res = await fetchCustPass72Check(custParams)
			this.$store.dispatch(
				'verify/setCustPassInfo',
				pass72Res.resultObject.decrypt
			)
			const custInfo = this.$store.getters['verify/customer']
			const custParam = pass72Res.resultObject.decrypt

			if (custInfo.custType === 'P') {
				// 개인
				const params = {
					custType: custInfo.custType,
					bahns: custParam.birYMD,
					name: custParam.name,
					name4: custParam.name,
					sex: custParam.sex === 'F' ? '2' : '1',
					mob: custParam.cellNo,
					katr6: custParam.fgnGbn === '1' ? 'A' : 'B',
					salesCd: this.$store.getters['common/codyNo']
				}
				this.$store.dispatch('verify/setCustParams', params)
				const result = await fetchCustCheckSelf(params)
				this.$store.dispatch(
					'verify/setCustInfo',
					result.resultObject.data.ES_CUST_INFO
				)
			} else {
				// 개인사업자
				const res = await fetchWebOrderInfoById(this.webOrderNo)
				const json = JSON.parse(res.resultObject.orderInfoJson)
				const params = {
					custType: custInfo.custType,
					bahns: custParam.birYMD,
					name: json.customer.bizName,
					name4: custParam.name,
					sex: custParam.sex === 'F' ? '2' : '1',
					mob: custParam.cellNo,
					katr6: custParam.fgnGbn === '1' ? 'A' : 'B',
					salesCd: this.$store.getters['common/codyNo'],
					usrLtdNo: json.customer.bizNo
				}
				this.$store.dispatch('verify/setCustParams', params)
				const result = await fetchCustCheckSelf(params)

				this.$store.dispatch('verify/setCustomerStore', {
					bizNo: json.customer.bizNo,
					bizName: json.customer.bizName
				})

				this.$store.dispatch(
					'verify/setCustInfo',
					result.resultObject.data.ES_CUST_INFO
				)
			}

			// 정기결제정보 목록 조회
			const res = await paymentInfo(this.webOrderNo, { zwebRecp: '04' })
			const paymentList = res.resultObject.data.PAYMENT_LIST
			this.payMethod = []

			paymentList.forEach(v => {
				if (v.PAY_TYPE === 'R') {
					if (v.AF_PAY_MTHD === '11' && this.paymentLimit === 'B') {
						// 은행이체 허용
						this.payMethod.push({
							...v,
							AF_PAY_MTHD_NM: this.accountText(v)
						})
					} else if (v.AF_PAY_MTHD === '12' && this.paymentLimit === 'C') {
						// 카드이체 허용
						this.payMethod.push({
							...v,
							AF_PAY_MTHD_NM: this.accountText(v)
						})
					} else if (this.paymentLimit === '') {
						this.payMethod.push({
							...v,
							AF_PAY_MTHD_NM: this.accountText(v)
						})
					}
				}
			})

			if (this.payMethod.length > 0) this.panel = 0

			// store에 있는 데이터가 있으면 추가로 그려주기 위함
			this.tempAccount()
		}
	},

	methods: {
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		isParameterEmpty,
		// 선택된 결제방법 해제 안되도록
		selectPaymentClick() {
			this.panel === undefined
			this.selectPaymentIdx = undefined
			this.showInitSelected = false
			this.selectPayment = []
			this.$store.dispatch('custom/setAccountInfo', {})
		},

		// 결제정보 삭제
		async listDel(seq, item) {
			const res = await this.$alert({
				message: '등록된 결제 정보를 삭제하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})

			if (res) {
				this.payMethod.splice(seq, 1)
				const chk = this.storeInfo.findIndex(
					x => x.accountNo === item.AF_CARD_NO
				)
				this.storeInfo.splice(chk, 1)
				this.$store.dispatch('custom/setTempAccount', this.storeInfo)

				// 선택된 카드와 삭제하는 카드가 동일하면 선택 해제
				// 등록된 결제수단이 없는 경우 선택 해제
				if (
					this.selectPayment.AF_CARD_NO === item.AF_CARD_NO ||
					this.payMethod.length === 0
				) {
					this.selectPayment = []
					this.selectPaymentIdx = undefined
				} else {
					//
				}
			}
		},

		// 결제정보 선택
		async select(item) {
			if (item.modifyRequired && item.ECODE === 'E001') {
				const msg =
					'선택한 결제정보는 현재 선택하실 수 없습니다. 다음 화면에서 정기결제일 오류 정보를 다른 정보로 선택해 주세요.'
				await this.$alert({ message: msg })
				this.payMethodPop(item)
				return
			}

			this.selectPayment = item
			this.payMethod.forEach((v, i) => {
				if (v.AF_CARD_NO === item.AF_CARD_NO) {
					this.payMethodSelectIdx = i + 1
				}
			})

			// 대표 정기결제정보로 저장
			this.$store.dispatch('custom/setAccountInfo', item)

			// 대표 수납결제정보로 저장
			const param = { grpCd: 'G6' }
			const paymentListCheck = await paymentList(param)

			// 수납 불가능한 목록
			let onceCd = []
			paymentListCheck.resultObject
				.filter(x => x.receiptYn === 'N')
				.forEach(v => {
					onceCd.push(v.cd)
				})

			// 카드, 은행 중 수납 가능한 정보 세팅
			if (
				item.AF_PAY_MTHD === '12' ||
				(item.AF_PAY_MTHD === '11' && !onceCd.includes(item.AF_BANK_CD))
			) {
				Object.assign(item, {
					PAY_TYPE: 'O'
				})

				this.$store.dispatch('custom/setOnceAccountInfo', item)
			}

			this.panel = undefined
			this.selectPaymentIdx = 0
		},

		init(value) {
			if (value === 0) {
				this.panel = 0
				this.selectPaymentIdx = 0
			} else {
				this.panel = undefined
			}
		},

		async payMethodPop(payInfo = {}) {
			//은행이체 결제일 체크
			await this.payDateCheck()
			this.$refs['payment-add-popup'].open({ ...payInfo })
		},

		// 카드 입력값을 화면에 보여주고 최종 선택된 값만 DB에 저장
		accountInfo(info) {
			const passNm = this.$store.getters['verify/custName']
			if (info) {
				if (
					this.payMethod.findIndex(x => x.AF_CARD_NO === info.accountNo) === -1
				) {
					this.payMethod.push({
						AF_BANK_CD_NM: info.bankNm,
						AF_PAY_MTHD_NM:
							passNm === info.ownerNm
								? info.payMethod === '12'
									? '카드이체'
									: '은행이체'
								: info.ownerNm,
						AF_CARD_NO: info.accountNo,
						AF_PAY_MTHD: info.payMethod,
						AF_BANK_CD: info.bankCd,
						AF_PAY_DD: info.dd,
						AF_SSN_NO: info.ssn,
						AF_OWNER_NM: info.ownerNm,
						AF_EXPIRE_YM: info.avDat,
						KATR2: info.katr2,
						SAP_YN: false
					})
					const selectedVal = this.payMethod.find(
						x => x.AF_CARD_NO === info.accountNo
					)
					this.storeInfo.push(info)
					//다음 화면에서 이전으로 왔을때 가지고 있어야 함으로
					this.$store.dispatch('custom/setTempAccount', this.storeInfo)
					this.select(selectedVal)
				} else {
					const pay = this.payMethod[
						this.payMethod.findIndex(x => x.AF_CARD_NO === info.accountNo)
					]

					pay.AF_PAY_DD = info.dd
					pay.modifyRequired = false
				}
			}
		},

		//패스인증결과값으로 sap에서 코디정보 가져오기
		async passResultProcess(retInfo) {
			if (retInfo) {
				let customer = JSON.parse(sessionStorage.getItem('customer'))
				await this.$store.dispatch('verify/setCustomer', customer)

				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				let custType = customer.custType
				const orderId = sessionStorage.getItem('orderId')
				fetchDecrypedPassVerifiedCust({ retInfo, orderId }).then(res => {
					//고객인증 정상
					let custPassInfo = res.resultObject
					this.$store.dispatch('verify/setCustPassInfo', custPassInfo)

					// 고객에게 발송된 안내문자메세지의 고객명/폰번호가 pass 인증된 고객명/폰번호 비교
					const diffResult = this.differentCustCheck(custPassInfo)
					if (diffResult) {
						fetchWebOrderInfoById(mobOrderNo).then(res => {
							let webParams = res.resultObject
							let json = JSON.parse(webParams.orderInfoJson)
							if (custType === 'P') {
								json = Object.assign(json, {
									diffCust: {
										message:
											'안내문자 발송된 고객정보와<br />PASS 인증된 고객정보가 다릅니다.<br /><br />고객명 확인 후 다시 인증요청해 주세요',
										name: custPassInfo.name,
										phone: custPassInfo.cellNo
									}
								})
							} else {
								json = Object.assign(json, {
									diffCust: {
										message:
											'안내문자 발송된 고객정보와<br />PASS 인증된 고객정보가 다릅니다.<br /><br />고객명 확인 후 다시 인증요청해 주세요.<br />개인사업자의 경우, 상호명이 아닌 고객이름을 입력해 주세요.',
										name: custPassInfo.name,
										phone: custPassInfo.cellNo
									}
								})
							}
							webParams.orderInfoJson = JSON.stringify(json)
							postWebOrderInfoById(mobOrderNo, webParams)
						})

						return
					}
					let params = {}
					let sex = custPassInfo.sex === 'F' ? '2' : '1'
					let korean = custPassInfo.fgnGbn === '1' ? 'A' : 'B'

					if (custType === 'P') {
						/**
						 * 개인 고객
						 */
						params = {
							custType: custType,
							bahns: custPassInfo.birYMD,
							name: custPassInfo.name,
							name4: custPassInfo.name,
							sex: sex,
							mob: custPassInfo.cellNo,
							katr6: korean,
							salesCd: this.$store.getters['common/codyNo'],
							salesType: this.$store.getters['common/type'],
							salesApp: this.$store.getters['common/app']
						}
						this.$store.dispatch('verify/setCustParams', params)

						fetchCustCheckSelf(params).then(async chkRes => {
							let custInfo = chkRes.resultObject.data.ES_CUST_INFO
							let rtn = chkRes.resultObject.data.E_RETURN
							await this.$store.dispatch('verify/setCustInfo', custInfo)
							if (rtn.TYPE === 'T') {
								let shouldUpdateCustInfo = false

								if (rtn.CODE === 'NE') {
									// 신규고객
									shouldUpdateCustInfo = true
								} else if (rtn.CODE === 'EX') {
									// 기존고객
									if (custPassInfo.cellNo !== custInfo.MOB_NUMBER) {
										const res = await this.updateInfo(params)
										if (res) {
											Object.assign(params, {
												kunnr: custInfo.KUNNR
											})
											shouldUpdateCustInfo = true
										}
									}
								}

								// 신규 고객의 경우
								if (shouldUpdateCustInfo) {
									let cAndURes = await postCustCreateAndUpdate(params)
									let custUpdateInfo = cAndURes.resultObject.data.ES_CUST_INFO
									let cAndURtn = cAndURes.resultObject.data.E_RETURN
									await this.$store.dispatch(
										'verify/setCustUpdateInfo',
										custUpdateInfo
									)

									// 신규고객 업데이트 성공 후 처리
									if (cAndURtn.TYPE === 'T') {
										if (!custUpdateInfo.KUNNR || custUpdateInfo.KUNNR === '') {
											this.setGuideMessage('', '고객번호가 없습니다.')
											return
										} else {
											await this.saveWebOrderInfo(custUpdateInfo.KUNNR)
										}
									} else {
										this.setGuideMessage('', cAndURtn.MESSAGE)
										return
									}
								} else {
									await this.saveWebOrderInfo(custInfo.KUNNR)
								}

								await this.paymentAdd()
							} else {
								if (
									rtn.CODE === 'OV' ||
									rtn.CODE === 'FR' ||
									rtn.CODE === 'FH' ||
									rtn.CODE === 'FI' ||
									rtn.CODE === 'BC'
								) {
									// 미납 연체 내역 => OV
									// 사기추정 고객  => FR
									// 가해약 고객    => FH
									// 대손 고객      => FI
									// 블랙컨슈머     => BC
									const result = await fetchWebOrderInfoById(mobOrderNo)
									let webParams = result.resultObject
									let json = JSON.parse(webParams.orderInfoJson)

									let msg = rtn.MESSAGE
									let guideMsg = msg
									if (rtn.CODE === 'OV') {
										msg =
											'미납/연체 금액이 있어 주문 진행이 불가합니다.<br />' +
											'영업정보시스템을 통해 미납/연체 금액을<br />' +
											'납부하고 이어서 진행해주세요.'
										guideMsg =
											'고객 인증을 정상적으로 완료했으나, <br />' +
											'미납/연체 금액이 있어 주문 진행이 <br />' +
											'불가능합니다. <br />' +
											'미납/연체 금액의 납부를 먼저 부탁드립니다. <br />' +
											'감사합니다.'
									} else if (rtn.CODE === 'FR') {
										msg = '정도경영팀에 의해 주문이 차단되었습니다.'
										guideMsg = msg
									} else if (rtn.CODE === 'FH') {
										msg =
											'가해약 고객은 주문접수가 불가합니다.<br />' +
											'영업정보시스템을 통해 가해약 취소 등록 후 이어서 진행해주세요.'
										guideMsg = '가해약 고객은 주문접수가 불가합니다.'
									} else if (rtn.CODE === 'FI') {
										msg = '대손 고객은 주문접수가 불가합니다.'
										guideMsg =
											'대손 고객은 주문접수가 불가합니다.<br />' +
											'자세한 내용은 담당 판매인에게<br />' +
											'문의해 주시기 바랍니다.'
									} else if (rtn.CODE === 'BC') {
										msg =
											'서비스 이용이 불가능합니다.<br />' +
											'콜센터(1588-5200)로 문의 부탁드립니다.'
										guideMsg =
											'클레임 고객으로 접수가 불가합니다.<br />' +
											'고객대응전략팀으로 문의하시기 바랍니다.'
									}

									json = Object.assign(json, {
										inaccessMsg: {
											message: msg,
											bahns: custPassInfo.birYMD,
											name: custPassInfo.name,
											name4: custPassInfo.name,
											sex: custPassInfo.sex === 'F' ? '2' : '1',
											mob: custPassInfo.cellNo,
											rtnType: rtn.TYPE,
											rtnCode: rtn.CODE,
											katr6: custPassInfo.fgnGbn === '1' ? 'A' : 'B'
										}
									})
									webParams.orderInfoJson = JSON.stringify(json)
									await postWebOrderInfoById(mobOrderNo, webParams)
									await this.saveWebOrderInfo2(custInfo.KUNNR, json.inaccessMsg)

									this.setGuideMessage('', guideMsg)
								} else {
									// 인증코드 발급 불가 => 'NA'
									this.setGuideMessage('', rtn.MESSAGE)
								}
							}
						})
					} else {
						/**
						 * 개인사업자 고객
						 */
						let bizNo = this.$store.getters['verify/bizNo']
						if (this.isParameterEmpty(bizNo)) {
							const param = {
								title: '비정상적인 접근입니다.',
								msg: '다시 접속하여 진행 부탁 드립니다.'
							}
							this.$store.dispatch('common/setGuideData', param)
							this.$router.push({ name: 'guide' })
							return
						}
						params = {
							custType: custType,
							name: this.$store.getters['verify/bizName'],
							name4: custPassInfo.name,
							mob: custPassInfo.cellNo,
							usrLtdNo: bizNo,
							bahns: custPassInfo.birYMD,
							sex: sex,
							katr6: korean,
							salesCd: this.$store.getters['common/codyNo'],
							salesType: this.$store.getters['common/type'],
							salesApp: this.$store.getters['common/app']
						}
						this.$store.dispatch('verify/setCustParams', params)

						fetchCustCheckSelf(params).then(async response => {
							const result = response.resultObject.data
							const rtn = result.E_RETURN

							await this.$store.dispatch(
								'verify/setCustInfo',
								result.ES_CUST_INFO
							)

							let custInputParams = JSON.parse(
								sessionStorage.getItem('custInputParams')
							)

							if (rtn.TYPE === 'T') {
								Object.assign(custInputParams, {
									bahns: custPassInfo.birYMD,
									sex: sex,
									katr6: custPassInfo.fgnGbn === '1' ? 'A' : 'B',
									usrLtdNo: bizNo
								})

								let mob = custPassInfo.cellNo

								if (rtn.CODE === 'NE') {
									Object.assign(custInputParams, {
										mob: mob
									})
								} else {
									if (params.mob !== result.ES_CUST_INFO.MOB_NUMBER) {
										const res = await this.updateInfo(params)
										if (!res) {
											mob = result.ES_CUST_INFO.MOB_NUMBER
										}
									}

									Object.assign(custInputParams, {
										kunnr: result.ES_CUST_INFO.KUNNR,
										mob: mob
									})
								}

								await this.$store.dispatch(
									'verify/setCustInputParams',
									custInputParams
								)

								const cAndURes = await postCustCreateAndUpdate(custInputParams)
								const custUpdateInfo = cAndURes.resultObject.data.ES_CUST_INFO
								const cAndURtn = cAndURes.resultObject.data.E_RETURN
								await this.$store.dispatch(
									'verify/setCustUpdateInfo',
									custUpdateInfo
								)

								if (cAndURtn.TYPE === 'T') {
									if (!custUpdateInfo.KUNNR || custUpdateInfo.KUNNR === '') {
										this.setGuideMessage('', '고객번호가 없습니다.')

										return
									} else {
										await this.showSnackbar({
											msg: '고객 인증이 정상적으로 완료되었습니다.',
											color: '#222'
										})

										// 웹주문정보저장
										await this.saveWebOrderInfo(custUpdateInfo.KUNNR)
										await this.paymentAdd()
									}
								} else {
									this.setGuideMessage('', cAndURtn.MESSAGE)
								}
							} else {
								await this.$store.dispatch(
									'verify/setCustInputParams',
									custInputParams
								)

								if (
									rtn.CODE === 'OV' ||
									rtn.CODE === 'FR' ||
									rtn.CODE === 'FH' ||
									rtn.CODE === 'ICB' ||
									rtn.CODE === 'FI' ||
									rtn.CODE === 'BC'
								) {
									// 미납 연체 내역 => OV
									// 사기추정 고객  => FR
									// 가해약 고객    => FH
									// 휴폐업(미등록) 개인사업자 => ICB
									// 대손 고객      => FI
									// 블랙컨슈머      => BC
									const result = await fetchWebOrderInfoById(mobOrderNo)

									let webParams = result.resultObject
									let json = JSON.parse(webParams.orderInfoJson)

									let msg = rtn.MESSAGE
									let guideMsg = msg
									if (rtn.CODE === 'OV') {
										msg =
											'미납/연체 금액이 있어 주문 진행이 불가합니다.<br />' +
											'영업정보시스템을 통해 미납/연체 금액을<br />' +
											'납부하고 이어서 진행해주세요.'
										guideMsg =
											'고객 인증을 정상적으로 완료했으나, <br />' +
											' 미납/연체 금액이 있어 주문 진행이 <br />' +
											'불가능합니다. <br />' +
											'미납/연체 금액의 납부를 먼저 부탁드립니다. <br />' +
											'감사합니다.'
									} else if (rtn.CODE === 'FR') {
										msg = '정도경영팀에 의해 주문이 차단되었습니다.'
										guideMsg = msg
									} else if (rtn.CODE === 'FH') {
										msg =
											'가해약 고객은 주문접수가 불가합니다.<br />' +
											'영업정보시스템을 통해 가해약 취소 등록 후 이어서 진행해주세요.'
										guideMsg = '가해약 고객은 주문접수가 불가합니다.'
									} else if (rtn.CODE === 'ICB') {
										msg = rtn.MESSAGE
										guideMsg =
											'폐업(미등록)한 사업자로 확인되어 주문을 진행할수 없습니다.'
									} else if (rtn.CODE === 'FI') {
										msg = '대손 고객은 주문접수가 불가합니다.'
										guideMsg =
											'대손 고객은 주문접수가 불가합니다.<br />' +
											'자세한 내용은 담당 판매인에게<br />' +
											'문의해 주시기 바랍니다.'
									} else if (rtn.CODE === 'BC') {
										msg =
											'서비스 이용이 불가능합니다.<br />' +
											'콜센터(1588-5200)로 문의 부탁드립니다.'
										guideMsg =
											'클레임 고객으로 접수가 불가합니다.<br />' +
											'고객대응전략팀으로 문의하시기 바랍니다.'
									}

									Object.assign(params, {
										message: msg,
										rtnType: rtn.TYPE,
										rtnCode: rtn.CODE
									})
									json = Object.assign(json, {
										inaccessMsg: params
									})

									webParams.orderInfoJson = JSON.stringify(json)
									await postWebOrderInfoById(mobOrderNo, webParams)
									await this.saveWebOrderInfo2(
										webParams.KUNNR,
										json.inaccessMsg
									)

									this.setGuideMessage('', guideMsg)
								} else {
									this.setGuideMessage('', rtn.MESSAGE)
								}
							}
						})
					}
				})
			}
		},
		async updateInfo(params) {
			return await this.$refs['phone-update'].open(params)
		},
		async payDateCheck() {
			await getPayDate({
				kunnr: this.$store.getters['verify/custNo']
			}).then(res => {
				const days = res.resultObject.data.E_TAB_DAYS

				this.payDd = days
			})
		},

		async paymentAdd() {
			const res = await paymentInfo(this.webOrderNo, {
				zwebRecp: '04'
			})

			const paymentList = res.resultObject.data.PAYMENT_LIST
			this.payMethod = []

			paymentList.forEach(v => {
				if (v.PAY_TYPE === 'R') {
					if (v.AF_PAY_MTHD === '11' && this.paymentLimit === 'B') {
						// 은행이체 허용
						this.payMethod.push({
							...v,
							AF_PAY_MTHD_NM: this.accountText(v)
						})
					} else if (v.AF_PAY_MTHD === '12' && this.paymentLimit === 'C') {
						// 카드이체 허용
						this.payMethod.push({
							...v,
							AF_PAY_MTHD_NM: this.accountText(v)
						})
					} else if (this.paymentLimit === '') {
						// 제한없음
						this.payMethod.push({
							...v,
							AF_PAY_MTHD_NM: this.accountText(v)
						})
					}
				}
			})

			if (this.payMethod.length > 0) this.panel = 0
			//store에 있는 데이터가 있으면 추가로 그려주기 위함
			this.tempAccount()
		},

		// 가이드 화면으로 이동
		setGuideMessage(title, message) {
			const params = {
				title: title,
				msg: message
			}
			this.$store.dispatch('common/setGuideData', params)
			this.$router.push({ name: 'guide' })
		},

		// 고객에게 발송된 안내문자메세지의 고객명/폰번호가 pass 인증된 고객명/폰번호와 다를 경우
		differentCustCheck({ name, cellNo: phone }) {
			const customer = this.$store.getters['verify/customer']

			if (!(name === customer.name && phone === customer.phone)) {
				const param = {
					msg:
						'안내문자 발송된 고객정보와 PASS 인증된 고객정보가 다릅니다.<br />판매인에게 문의해 주세요.'
				}

				this.$store.dispatch('common/setGuideData', param)
				this.$router.push({ name: 'guide' })

				return true
			} else {
				return false
			}
		},

		async saveWebOrderInfo(custNo) {
			let customer = this.$store.getters['verify/customer']
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			await codyMatchingValidate({
				webOrderNo: mobOrderNo,
				empNo: customer.codyNo,
				customerTelNo: customer.phone
			}).catch(error => this.$log(error))

			let pathParams = {
				codyNo: this.$store.getters['common/codyNo'], // 코디번호 코디사번
				custNo: custNo
			}

			const res = await fetchWebOrderInfoById(mobOrderNo)
			const json = JSON.parse(res.resultObject.orderInfoJson)

			let webParams = {}
			webParams.step = '02'
			webParams.statusCode = '102'
			webParams.id = sessionStorage.getItem('orderId')
			customer.codyNo = this.$store.getters['common/codyNo']
			customer.matchingId = json.matchingId
			customer.inbound = json.inbound
			customer.codyPhoneNo = this.$store.getters['common/codyPhoneNo']
			customer.app = this.$store.getters['common/app']
			let jsonStr = JSON.stringify({
				customer
			})
			webParams.orderInfoJson = jsonStr

			const userKey = this.$store.getters['verify/userKey']
			if (userKey) webParams.userKey = userKey

			await postWebOrderInfo(pathParams, webParams).then(res => {
				this.$store.dispatch('verify/setMobOrderNo', res.resultObject.id)
			})
		},

		async saveWebOrderInfo2(custNo, inaccessMsg) {
			this.$log('웹주문 저장 고객번호(차단)', custNo)
			let pathParams = {
				codyNo: this.$store.getters['common/codyNo'], // 코디번호 코디사번
				custNo: custNo
			}

			this.$log('고객번호 출력4 ====>>>', custNo)

			let webParams = {}
			webParams.step = '01'
			webParams.statusCode = '201'
			webParams.id = sessionStorage.getItem('orderId')
			let customer = this.$store.getters['verify/customer']
			customer.codyNo = this.$store.getters['common/codyNo']
			customer.codyPhoneNo = this.$store.getters['common/codyPhoneNo']
			customer.app = this.$store.getters['common/app']
			let jsonStr = JSON.stringify({
				customer,
				inaccessMsg
			})
			webParams.orderInfoJson = jsonStr

			const userKey = this.$store.getters['verify/userKey']
			if (userKey) webParams.userKey = userKey

			await postWebOrderInfo(pathParams, webParams).then(res => {
				this.$store.dispatch('verify/setMobOrderNo', res.resultObject.id)
			})
		},

		next() {
			this.$router.push({
				name: 'cust-setupadd'
			})
		},

		// 결제정보 임시 저장
		tempAccount() {
			const tempAccount = this.$store.getters['custom/tempAccount']
			const passNm = this.$store.getters['verify/custName']
			if (tempAccount.length > 0) {
				let info = tempAccount
					.filter(x => x.payType === 'R')
					.map(p => {
						return {
							AF_BANK_CD_NM: p.bankNm,
							AF_PAY_MTHD_NM:
								passNm === p.ownerNm
									? p.payMethod === '12'
										? '카드이체'
										: '은행이체'
									: p.ownerNm,
							AF_CARD_NO: p.accountNo,
							AF_PAY_MTHD: p.payMethod,
							AF_BANK_CD: p.bankCd,
							AF_PAY_DD: p.dd,
							AF_SSN_NO: p.ssn,
							AF_OWNER_NM: p.ownerNm,
							AF_EXPIRE_YM: p.avDat,
							del: true
						}
					})
				this.storeInfo = [...tempAccount]
				this.payMethod = [...this.payMethod, ...info]

				this.payMethod = this.payMethod.filter((item1, idx1) => {
					return (
						this.payMethod.findIndex(item2 => {
							return item1.AF_CARD_NO == item2.AF_CARD_NO
						}) == idx1
					)
				})
				if (this.payMethod.length > 0) this.panel = 0
			}
		},

		accountText(x) {
			const passNm = this.$store.getters['verify/custName']
			const name = passNm === x.AF_OWNER_NM ? x.AF_PAY_MTHD_NM : x.AF_OWNER_NM
			return name
		}
	}
}
</script>
